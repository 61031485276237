import React from 'react';
import PropTypes from "prop-types";
import InformationItem from './InformationItem';

const InformationSet = ({data}) => {
    return (
      <div className="flex flex-wrap justify-center">
        {data.map((information, i) => (
          <InformationItem
            key={i}
            index={i}
            icon={information.icon}
            title={information.title}
            subtitle={information.subtitle}
            link={information.link}
          />
        ))}
      </div>
    );
}

InformationSet.propTypes = {
  data: PropTypes.array.isRequired,

};

export default InformationSet;