import React from 'react';
import Security from "../../images/security.png";
import USB from "../../images/usb.png";
import Construction from "../../images/construction.png";
import Toolbox from "../../images/baseline.png";
import Information from '../common/Information/Information';
import SliderSection from '../common/SliderSection';
import Image1 from "../../images/slider/11.png"
import Image2 from "../../images/slider/12.png"
import Image3 from "../../images/slider/13.png"
const PhyiscalData = () => {
    const link = "detailedOfferings#1";
    return (
      <div id="physical-data" className="container h-auto mx-auto mt-12">
          <SliderSection 
          images={[Image1, Image2, Image3]}
          title="Physical Data Center Solutions"
          description="Partnering with Unboggled gives you a guarantee for competitiveness, continuity and uptime for your business operations. Our flexibility, proficiency and forward-looking data center solutions ensure you can choose the ideal option that works best for you."
          link={link}

          />
        <Information
          data={[
            {
              icon: Security,
              title: "Physical Security, Fire and Life Safety",
              subtitle:
                "Today, businesses are reliant on efficient, reliable and secure data centers to keep operations going. Unboggled uses state-of-the-art security features and procedures, and assists in project management of fire protection systems modeled by our partner. Upgrading your security and fire/life safety infrastructure has never been easier.",
                link:`${link}-1`
            },
            {
              icon: USB,
              title: "Low Voltage Cabling and Wire Management",
              subtitle:
                "Unboggled offers a wide-range of industrial-grade cable installation and management solutions. We specialize in low voltage cabling and fiber optics. Unboggled follows manufacturer bend radius guidelines, installs vertical and horizontal wire management devices, ladder rack, cable tray, and uses several structured cabling techniques for easy identification and troubleshooting.",
                link:`${link}-2`

            },
            {
              icon: Construction,
              title: "Physical Infrastructure Deployment",
              subtitle:
                "Unboggled’s unique approach to infrastructure deployment has eliminated business and technology pressures for many of our clients. Our infrastructure management system allows clients to optimize data center floor and rack space. The deployment process can be done all at once, or in a phased approach design allowing for growth.",
                link:`${link}-3`

            },
            {
              icon: Toolbox,
              title: "Racking and Stacking",
              subtitle:
                "We don’t just rack all your equipment randomly. Unboggled will work with you to come up with cabinet elevation and rack diagrams to ensure your equipment is racked the way you need it to be. Network connectivity and power cabling pathways are identified to create cabinets and racks which allow ample access to all equipment. ",
                link:`${link}-4`

            },
          ]}
          link={link}

        />
      </div>
    );
}

export default PhyiscalData;