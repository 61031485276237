import React from 'react';
import PropTypes from 'prop-types'

const Caret = ({className}) => {
  return (
    <svg
      width="22"
      height="13"
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <line
        x1="1.41421"
        y1="2"
        x2="11"
        y2="11.5858"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="14.5563"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 22 2)"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Caret.propTypes = {
  className: PropTypes.string
}

Caret.defaultProps = {
  className: ''
}

export default Caret;