import React from 'react';
import Information from '../common/Information/Information';
import SliderSection from '../common/SliderSection';
import Analytics from "../../images/analytics.png" 
import Work from "../../images/work.png" 
import Arrows from "../../images/arrows.png" 
import Trash from "../../images/trash.png" 
import Chart from "../../images/chart.png" 
import Image1 from "../../images/slider/21.png"
import Image2 from "../../images/slider/22.png"
import Image3 from "../../images/slider/23.png"

const Technology = () => {
    const link = "detailedOfferings#2"
    return (
      <div id="technology" className="container h-auto mx-auto mt-40">
          <SliderSection 
          images={[Image1, Image2, Image3]}
          reverse={true}
          title="Data Center and Technology Services"
          description="Maintenance and optimization of your data center is crucial to keep mission critical operations uninterrupted. Our Service Team of Experts deliver on services that ensure your data center is fully functional and caters to your relocation, expansion or decommissioning needs."
          link={link}
          />
        <Information
          data={[
            {
              icon: Work,
              title: "Technology Project Management",
              subtitle:
                "Having expert support and supervision is essential to ensuring technology centered projects are finished on time and within budget . Unboggled brings a team of skilled IT and experienced data center professionals to the table along with a list of trusted partners who provide best in class service and product support. We are ready to help you tackle any technology based portion of your next project!",
              link:`${link}-1`
            },
            {
              icon: Arrows,
              title: "Data Center Migration / Equipment Relocation / Colocation Relocation",
              subtitle:
                "Who enjoys moving? Let Unboggled get your gear from point A to B. With Unboggled as your partner, you will no longer need to worry about your data center migration. Our experienced Project Planning Team uses industry-standard methodologies and state-of-the-art transport mechanisms to facilitate safe and efficient data center migration. Equipment dimensions and weights are documented for custom, form fitting molds and boxes.",
              link:`${link}-2`
            },
            {
              icon: Trash,
              title: "Equipment Decommissioning and Removal",
              subtitle:
                "At Unboggled, we have an experienced team ready to undertake site decommissioning and removal services. These services encompass the entire equipment removal spectrum through inventory cataloging, asset verification, documenting device dependencies, onsite data erasure, device destruction, disposable and/or recycling. We also provide updates to existing documentation to reflect site changes. ",
              link:`${link}-3`
            },
            {
              icon: Chart,
              title: "Floorplan and Cabinet Elevation Diagrams",
              subtitle:
                "The complexity of data centers can be tasking for internal teams. Unboggled has unmatched experience in creating strategic solutions through floorplan and cabinet elevation diagrams that maximize space and capacity, lower power and cooling costs, and forecast for growth. Drill down floor plans are a great way to visualize your asset inventory and provide a reference of what you have, where it is and how it is connected.",
              link:`${link}-4`
            },
            {
              icon: Analytics,
              title: "Inventory and Asset Management ",
              subtitle:
                "Are you aware of every asset in your enterprise data center? The framework designed by our expert team at Unboggled allows you to perform periodic audits and process analysis to keep track of all physical and virtual assets, ensuring workflows are uninterrupted. Creating an inventory with essential data saves you time and money. We offer barcode and RFID tagging techniques to reference all assets within your inventory.",
              link:`${link}-5`
            },
          ]}
          link={link}
        />
      </div>
    );
}

export default Technology;
