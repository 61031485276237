import React from "react";
import { Link } from "gatsby";
// import SEO from "../components/seo";
import TsParticles from "../components/TsParticles";
import { ProgressAwareContainer } from "../components/common/ProgressAwareContainer";
import AppLayout from "../layout/AppLayout";
import Hero from "../components/hero/Hero";
import Technology from "../components/home/Technology";
import PhyiscalData from "../components/home/PhyiscalData";
import DataCenter from "../components/home/DataCenter";

function IndexPage() {
  //
  return (
    <AppLayout
      routes={[
        { name: "Data Center Physical Solutions", href: "#physical-data" },
        { name: "Technology Services", href: "#technology" },
        { name: "Data Center Consulting", href: "#data-center" },
        { name: "Contact Us", href: "contact" },
      ]}
    >
      <Hero />
      <ProgressAwareContainer>
        {/* <SEO
          keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
          title="Home"
        /> */}
        <div className="relative h-screen">
          <div className="absolute inset-0 h-screen overflow-hidden">
            <TsParticles />
          </div>
        </div>
        <PhyiscalData />
        <Technology />
        <DataCenter />
        <hr className="mt-8" />
        <div className="pb-5 mt-5" id="contact">
          <Link to="/contact">
            <button className="block px-5 py-2 mx-auto duration-100 border border-black rounded-md hover:bg-black hover:text-white">
              Contact Us
            </button>
          </Link>
        </div>
      </ProgressAwareContainer>
    </AppLayout>
  );
}

export default IndexPage;
