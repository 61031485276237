import React from "react";
import PropTypes from "prop-types";
import Caret from "../../misc/Caret";
import { Link } from "gatsby";

const InformationItem = (props) => {
  const { icon, title, subtitle, link="" } = props;
  return (
    <Link
      className="flex flex-col justify-between w-4/5 px-3 py-2 mx-3 mb-6 duration-150 ease-in-out border border-black rounded-md lg:mb-0 sm:w-2/5 lg:w-1/5 hover:bg-gray-200"
      to={link}
    >
      <div className="flex justify-center m-5">
        <img src={icon} />
      </div>
      <div className="flex-grow">
        <p className="font-bold text-center text-md">{title}</p>
        <p className="mt-4 text-center text-md">{subtitle}</p>
      </div>
      <div className="relative bottom-0">
        <p className="pt-3 mb-2 font-bold text-center">Learn More</p>
        <p>
          <Caret className="block mx-auto animate-bounce" />
        </p>
      </div>
    </Link>
  );
};

InformationItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  index: PropTypes.number,
  link: PropTypes.string,
};

export default InformationItem;
