import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InformationSet from "./InformationSet";
import Arrow from "../../common/Arrow"


// function Arrow({ className, style, onClick, direction="next" }) {
//   // // eslint-disable-next-line no-debugger
//   // debugger;
//   console.log(className)
//   const content = direction === "next" ? "&gt;" : "&lt;"
//   return (
//     <div
//       className={className + " bg-black w-1 findMe"}
//       style={{ ...style, display: "block", color: "blue"}}
//       onClick={onClick}
//     >{content}</div>
//   );
// }
// Arrow.propTypes = {
//   className: PropTypes.string,
//   style: PropTypes.object,
//   onClick: PropTypes.func,
//   direction: PropTypes.oneOf(["next", "prev"])
// };

const Information = ({ data}) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />
  };
  // change the array to increment in 4s
  const splitUpArr = () => {
    let arrays = [];
    let arrIndex = 0;
    // how many will be shown per slide
    const perSlide = 4;
    data.forEach((val) => {
      if (!arrays[arrIndex]) {
        arrays[arrIndex] = [];
      }
      arrays[arrIndex].push(val);
      if (arrays[arrIndex].length === perSlide) {
        arrIndex++;
      }
    });
    return arrays;
  };

  return (
    <section className="container mx-auto mt-12">
      <Slider {...settings}>
        {splitUpArr().map((items, i) => (
          <InformationSet key={i} data={items}/>
        ))}
      </Slider>
    </section>
  );
};

Information.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Information;
