import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const ProgressAwareContainer = ({
  children,
  progressMargin = 'ml-4',
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  function onScroll() {
    requestAnimationFrame(() => {
      const scrollTop = window.pageYOffset;
      const winHeight = window.innerHeight;
      const docHeight = getDocHeight();

      const totalDocScrollLength = docHeight - winHeight;
      const scrollPosition = Math.floor(
        (scrollTop / totalDocScrollLength) * 100
      );

      setPercentage(scrollPosition);
    });
  }

  function getDocHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  return (
    <div className="relative">
      <div className="fixed inset-0 pointer-events-none flex items-center z-0">
        <div
          className={`rounded bg-gray-300 h-20 w-2 relative ${progressMargin}`}
        >
          <div
            className="rounded bg-gray-700 absolute w-full"
            style={{ height: `${percentage}%`, minHeight: '0.5rem' }}
          ></div>
        </div>
      </div>
      <div className="z-10">{children}</div>
    </div>
  );
};

ProgressAwareContainer.propTypes = {
  children: PropTypes.node.isRequired,
  progressMargin: PropTypes.string,
};
