import React from 'react';
import Information from '../common/Information/Information';
import SliderSection from '../common/SliderSection';
import Cogwheel from "../../images/cogwheel.png";
import Rowboat from "../../images/rowboat.png";
import Wrench from "../../images/wrench.png";
import Sheet from "../../images/sheet.png";
import Image1 from "../../images/slider/31.png"
import Image2 from "../../images/slider/32.png"
import Image3 from "../../images/slider/33.png"
const DataCenter = () => {
    const link = "detailedOfferings#3";
    return (
      <div id="data-center" className="container h-auto mx-auto mt-40">
          <SliderSection 
          images={[Image1, Image2, Image3]}
          title="Data Center Consulting"
          description="The economics of building, upgrading or colocating IT infrastructure can be daunting for most IT staff and Real Estate advisors. At Unboggled, we approach data center consultation from a lifecycle perspective, offering power and cooling assessments, site assessments and innovative designs for your business needs. We have a top-down approach and achieve a granular level of detail you will not find elsewhere."
          link={link}
          />
        <Information
          data={[
            {
              icon: Cogwheel,
              title: "Data Center Assessment",
              subtitle:
              "Unboggled’s focuses on three facets of the data center: space, power and interconnectivity. We perform an on-site evaluation of your data center facilities, provide benchmarks, identify single points of failure and provide best practices and post implementation suggestions. We also provide critical equipment and room design evaluations for strategic planning",
              link:`${link}-1`
            },
            {
              icon: Rowboat,
              title: "Business Continuity Disaster Recovery Planning and Testing",
              subtitle:
                "Unboggled will craft a disaster recovery plan based upon your geographic location(s), data center deployment and network architecture. Unboggled has exercised real world BCDR plans and knows a plan can not be deemed effective until tested. Partnering with Unboggled safeguards the reliability and availability of mission critical applications and data.",
              link:`${link}-2`
            },
            {
              icon: Wrench,
              title: "Data Center Maintenance Planning",
              subtitle:
              "Skilled operations teams are necessary to facilitate maintenance and lifecycle strategies for data centers. Unboggled’s predictive and preventive maintenance plans have helped our clients eliminate the risk of down time and allow data centers to run efficiently. Unboggled saves you time and money while giving you risk averse maintenance options.",
              link:`${link}-3`
            },
            {
              icon: Sheet,
              title: "Colocation Evaluations and Comparison",
              subtitle:
                "Unboggled assists customers in finding the colocation data center that suits them best. We compile the critical data so you can accurately compare, contrast and pick the best colocation space for your needs. Our rigid vendor and data center evaluation checklist will provide you the answers you need to make the best decision for your company.",
              link:`${link}-4`
            },
          ]}
          link={link}
        />
      </div>
    );
}

export default DataCenter;
