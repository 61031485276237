import React from 'react';
import Logo from '../misc/Logo';

const Hero = () => {
    return (
      <div className="absolute inset-0 flex items-center justify-center h-screen font-roboto">
        <div className="xl:mr-40">
          <p className="font-semibold text-center sm:text-left sm:text-lg">
            Simple solutions for complex problems.
          </p>
          <div className="flex flex-col items-center text-4xl font-bold sm:text-5xl sm:flex-row">
            <div className="inline-flex mr-4">
              <h2 className="pr-2 -mr-2 border-black border-b-3">Wel</h2>
              <h2>come</h2>
            </div>
            <span className="mr-4">to</span>
            <Logo className="w-56 sm:w-auto" />
          </div>
          <p className="inline-flex max-w-sm mt-12 leading-8 text-center sm:text-left">
            We are a team of experienced data center professionals who provide
            the granular level of detail and attention required to complete
            projects within critical environments. As a veteran owned business,
            we know what service is, and provide an unrivaled level of
            dedication to our customers.
          </p>
        </div>
      </div>
    );
}

export default Hero;